
import { defineComponent, reactive } from 'vue';
import MergePlatformTitle from '@/views/production/components/MergePlatformTitle.vue';
import DescriptionWithCircle from '@/views/production/components/DescriptionWithCircle.vue';

export default defineComponent({
	name: 'MergePlatformDevice',
	components: { DescriptionWithCircle, MergePlatformTitle },
	setup() {
		const iconTitleOptions = reactive({
			imgUrl: require('@/assets/icon_platform_device@2x.png'),
			title: '设备集中管控',
			description: '远程管控全校智慧教室，智能运维更轻松'
		});

		const features = reactive([
			{
				title: '可视化设备管控',
				description: ['支持通过视频查看教室设备的使用情况，来灵活管控教室环境', '可批量控制全校智慧教室终端、投影幕布/教学大屏、电脑、灯光等设备状态，高效便捷']
			},
			{
				title: '智能物联管控',
				description: ['智慧教室环境自动监测（湿度、温度、PM2.5等），智能控制灯光、空调、窗帘等设备，优化教学环境，节约能耗', '物联模式智能联动，登录模式、录播模式、投影模式、下课模式自定义']
			},
			{
				title: '远程协助',
				description: ['线上协助，接管教师电脑、中控控制屏，远程设备维护，运维更轻松', '管理员与教室实时IP对讲，及时沟通排除问题']
			},
			{
				title: '智能运维系统',
				description: ['支持批量升级教室硬件系统，扩展新功能、新应用']
			},
			{
				title: '智能巡检',
				description: ['设备故障主动告警通知，快速定位助力故障排除']
			}
		]);

		return {
			iconTitleOptions,
			features
		};
	}
});
