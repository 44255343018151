<template>
	<div class="ai-camera">
		<div class="bg-b-white">
			<div v-lazy-bg:img="[require('@/assets/blur/ai_camera_bg.jpg'),true,require('@/assets/production/ai_camera_bg.png')]" class="ai-camera-bg">
				<div class="p-l-40 p-t-260 col-b-title">
					<p class="f-s-42 f-w-600">小学课堂人脸识别率＞99%，</p>
					<p class="f-s-42 f-w-600 col-b-blue">真正做到“一个都不能少”</p>
					<p class="f-s-22 m-t-40 opacity-7 w-500 l-h-30">
						基于高性能边缘计算理念，实现自动AI图像识别，无死角覆盖教室每个学生，伴随式采集学生情绪、行为等多维数据，实时分析
					</p>
				</div>
			</div>
			<div class="w-1200 text-center block-center col-b-title p-t-90 p-b-80">
				<p class="f-s-42 f-w-600">无感知考勤</p>
				<p class="f-s-42 f-w-600">辅助教学改革与评估</p>
				<p class="f-s-20 f-w-400 opacity-6 desc">学生考勤、到课率、抬头率、教室空座率等数据统计</p>
				<div class="dotted-line block-center">
					<inter-image-show
						:img-width="1078"
						:img-height="508"
						:img-url="require('@/assets/production/ai_camera1.png')"
					></inter-image-show>
				</div>
			</div>
			<div class="bg-b-blue">
				<div class="w-1200 block-center col-b-title flex">
					<inter-image-show
						:img-width="720"
						:img-height="448"
						:img-url="require('@/assets/production/ai_camera2.png')"
					></inter-image-show>
					<div class="p-l-9 col-b-white m-t-135" style="margin-top: 135px">
						<p class="f-s-42 f-w-600">伴随式数据采集，</p>
						<p class="f-s-42 f-w-600">全面监测课堂教学</p>
						<p class="f-s-22 f-w-400 m-t-32 opacity-7 desc l-h-30">
							内置5T FLOPS强大算力的边缘AI处理器，一节课>1000次全体学生行为数据采集。
						</p>
					</div>
				</div>
			</div>
			<div class="w-1200 block-center col-b-title p-t-90 p-b-80">
				<div
					v-lazy-bg:img="require('@/assets/production/ai_camera_icon.png')"
					class="expression"
				></div>
				<p class="f-s-42 f-w-600">学生情绪、行为等</p>
				<p class="f-s-42 f-w-600">多维数据实时分析</p>
				<p class="f-s-20 f-w-400 opacity-7 desc w-500 l-h-32">
					读写、听讲、举手、趴桌子、玩手机等学生行为检测;<br />愤怒、悲伤、恐惧、高兴、惊讶、平常等学生情绪检测。
				</p>
				<div class="dotted-line block-center">
					<inter-image-show
						:img-width="1186"
						:img-height="335"
						:img-url="require('@/assets/production/ai_camera3.png')"
					></inter-image-show>
				</div>
			</div>
		</div>
		<div v-lazy-bg:img="require('@/assets/production/ai_camera4.png')" class="ai-camera-bg bg-end m-b-10 m-t-10">
			<div class="w-1200 block-center col-b-white">
				<p class="f-s-42 f-w-600">高安全性人脸识别算法，</p>
				<p class="f-s-42 f-w-600">保障师生信息安全</p>
				<p class="f-s-20 f-w-400 opacity-7 w-496 desc l-h-32">
					自动将人脸转化成特征码和校存储信息人脸特征码进行比对，达到身份识别目的，保障人脸信息安全。
				</p>
			</div>
		</div>
		<div
			v-lazy-bg:img="require('@/assets/production/ai_camera5.png')"
			class="ai-camera-bg bg-end m-b-10 col-b-white"
		>
			<div class="block-center col-b-white right">
				<p class="f-s-42 f-w-600">多镜头列阵，</p>
				<p class="f-s-42 f-w-600">自动抓拍实证画面</p>
				<p class="f-s-20 f-w-400 opacity-7 w-336 desc l-h-32">
					无死角覆盖每一个学生，自动抓拍学生玩手机、趴桌子、睡觉等实证画面
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import interImageShow from '@/components/interImageShow';
import { defineComponent } from 'vue';
export default defineComponent({
	name: 'AiCamera',
	components: {
		interImageShow
	}
});
</script>

<style scoped lang="scss">
.ai-camera {
	.ai-camera-bg {
		height: 720px;
		padding-left: 50%;
		background-size: cover;
		background-position: center ;
		background-repeat: no-repeat;
		box-sizing: border-box;
	}
	.expression{
		width: 360px;
		height:45px;
		margin-bottom: 32px;
		background-repeat: no-repeat;
	}
	.desc {
		margin-top: 32px;
	}
	.dotted-line {
		margin-top: 22px;
	}
	.ai-camera-bg.bg-end {
		height: 480px;
		padding-left: 0;
		padding-top: 133px;
		.w-496 {
			width: 496px;
		}
		.w-336 {
			width: 336px;
		}
		.right {
			padding-left: 60%;
		}
	}
	.p-t-260 {
		padding-top: 260px;
	}
}
</style>
