import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b8d0852"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wifi-terminal-feature flex justify-center bg-light-grey" }
const _hoisted_2 = {
  class: "w-1200 flex-shrink-0",
  style: {"height":"476px"}
}
const _hoisted_3 = {
  class: "feature-item-container flex flex-wrap justify-between",
  style: {"margin-top":"81px"}
}
const _hoisted_4 = { class: "feature-img w-88 h-88 flex-shrink-0" }
const _hoisted_5 = {
  class: "feature-text flex-shrink-0 p-l-30 col-b-title opacity-7 f-w-400 f-s-20 text-justify",
  style: {"width":"214px","line-height":"32px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_lazy_bg = _resolveDirective("lazy-bg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.features, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "feature-item flex",
            style: {"margin-bottom":"91px"}
          }, [
            _withDirectives(_createElementVNode("div", _hoisted_4, null, 512), [
              [_directive_lazy_bg, item.imgUrl, "img"]
            ]),
            _createElementVNode("div", _hoisted_5, _toDisplayString(item.description), 1)
          ]))
        }), 128))
      ])
    ])
  ]))
}