
import { defineComponent, reactive } from 'vue';

export default defineComponent({
	name: 'WIFITerminalFeature',
	setup() {
		const features = reactive([
			{
				imgUrl: require('@/assets/wifi_setting@2x.png'),
				description: '0布线，0繁琐，全无线连接技术，5 分钟搭建拍摄场景'
			},
			{
				imgUrl: require('@/assets/wifi_chip@2x.png'),
				description: '0调试，开机即用，硬件采用ARM+DSP多核处理器'
			},
			{
				imgUrl: require('@/assets/wifi_proof@2x.png'),
				description: '轻巧便携，整套设备及配件可内置于26寸的航空箱中，耐摔耐撞'
			},
			{
				imgUrl: require('@/assets/wifi_operate@2x.png'),
				description: '触控操作，内嵌液晶显示屏，使用简单'
			},
			{
				imgUrl: require('@/assets/wifi_interface@2x.png'),
				description: '兼容第三方设备，互联互通，无缝对接任一标准H.323互动终端'
			},
			{
				imgUrl: require('@/assets/wifi_video@2x.png'),
				description: '录制、直播、点播、互动、视频上传/存储/下载，功能强大'
			}
		]);

		return {
			features
		};
	}
});
