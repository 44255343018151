
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'MergePlatformTitle',
	props: {
		imgUrl: {
			type: String,
			default: ''
		},
		imgWidth: {
			type: [String, Number],
			default: 100
		},
		imgHeight: {
			type: [String, Number],
			default: 100
		},
		title: {
			type: String,
			default: ''
		},
		description: {
			type: String,
			default: ''
		},
		isTextWhite: {
			type: Boolean,
			default: false
		}
	}
});
