<template>
	<section class='w-100p p-t-50 border-box board h-800 relative'>
		<div class='absolute w-100p h-100p board-img'
			 v-lazy-bg:img='[require("@/assets/blur/smart_block_bg.jpg"),true,require("@/assets/smartBoard/smartBlackboardBg.png")]'></div>
		<div class='w-1200 block-center relative'>
			<div class='col-blue f-s-42 f-w-600 board-black'>坐在后排能看得清黑板，</div>
			<div class='col-title f-s-42 f-w-600'>没抄上笔记也不用着急</div>
			<div class='col-title f-s-22 f-w-600 m-t-40 board-des l-h-30 text-justify'>基于人工智能的图像识别技术，内置自动去遮挡及图像增强功能，自动消除教师遮挡，100%还原板书，将纯净板书内容同步显示于辅显屏，便于后排学生观看</div>
		</div>
	</section>
	<section class='w-100p occlusion m-t-70 flex align-center'>
		<div class='w-1200 block-center  col-b-white'>
			<div class='f-s-42 f-w-600'>智能消除教师遮挡</div>
			<div class='f-s-22 m-t-30 occlusion-des l-h-30 col-white-opacity'>自动识别遮挡对象，并智能消除遮挡目标，仅保留纯净板书内容。</div>
		</div>
	</section>
	<section class='w-100p write m-t-20 flex align-center'>
		<div class='w-1200 block-center col-title'>
			<div class='write-des'>
				<div class='f-s-42 f-w-600 '>顺应老师板书习惯</div>
				<div class='f-s-22 m-t-30 occlusion-des l-h-30 col-gray'>教师无需改变任何黑板板书习惯，同时实现板书内容信息化。</div>
			</div>
		</div>
	</section>
	<section class='w-100p white-bg'>
		<div class='w-1200 block-center reduction border-box'>
			<div class='flex-space-b align-start'>
				<div class='f-s-42 col-title '>
					<div class='f-w-600'>100%还原板书，</div>
					<div class='f-w-600'>保留书写风采</div>
				</div>
				<div class='reduction-one'>
					<description-with-circle description='双目超大广角拍摄，无死角识别板书细节，100%复刻板书内容；'></description-with-circle>
				</div>
				<div class='reduction-two'>
					<description-with-circle description='精准识别黑板上的细微操作，完整保留书写笔锋，真实还原板书。'></description-with-circle>
				</div>
			</div>
			<inter-image-show
				class='text-center m-t-40'
				:img-width="1010"
				:img-height="364"
				:img-url="require('@/assets/smartBoard/reduction.png')"
			></inter-image-show>
		</div>
	</section>
	<section class='w-100p block'>
		<div class='w-1200 block-center  border-box '>
			<div class='flex-space-b'>
				<div>
					<div class='f-s-42'>
						<div class='f-w-600 col-title'>
							适应各类黑板，
						</div>
						<div class='f-w-600 col-title'>
							100%利旧
						</div>
					</div>
					<div class='adapt-one'>
						<description-with-circle description='支持固定式、推拉式、升降式等黑板；支持黑板、绿板、白板等颜色黑板；支持各类粉笔颜色；' descriptionColor=' rgba(54,57,65,0.7)'></description-with-circle>
					</div>
					<div class='adapt-two'>
						<description-with-circle description='无需更换教室现有黑板，有效保护原有投资。' descriptionColor=' rgba(54,57,65,0.7)'></description-with-circle>
					</div>
				</div>
				<inter-image-show
					:img-width="802"
					:img-height="481"
					:img-url="require('@/assets/smartBoard/adapt.png')"
				></inter-image-show>
			</div>
		</div>
	</section>
	<section class='w-100p white-bg'>
		<div class='w-1200 block-center adapt border-box '>
			<div class='flex-space-a'>
				<inter-image-show
					:img-width="600"
					:img-height="337"
					:img-url="require('@/assets/smartBoard/switch.png')"
				></inter-image-show>
				<div>
					<div class='f-s-42'>
						<div class='f-w-600 col-title'>
							板书画面与教师桌面
						</div>
						<div class='f-w-600 col-title'>
							自动切换
						</div>
					</div>
					<div class='col-gray adapt-des'>
						辅显屏呈现画面自适应教师授课状态，实现板书与教师课件自动切换。
					</div>
				</div>
			</div>
		</div>
	</section>
	<section class='w-100p product flex align-center'>
		<div class='w-1200 block-center'>
			<div class='f-s-42 f-w-600'>适应各种复杂光线环境</div>
			<div class='f-s-20 m-t-30 product-des l-h-30 col-gray'>内置图像增强功能，能进行实时画质增强，适应各种复杂光线环境使用。</div>
		</div>
	</section>
	<section class='w-100p white-bg'>
		<div class='w-1200 block-center adapt border-box '>
			<div class='flex-space-a'>
				<inter-image-show
					:img-width="600"
					:img-height="337"
					:img-url="require('@/assets/smartBoard/code.png')"
				></inter-image-show>
				<div>
					<div class='f-s-42'>
						<div class='f-w-600 col-title'>
							板书、PPT自动生成
						</div>
						<div class='f-w-600 col-title'>
							课堂笔记，
						</div>
						<div class='f-w-600 col-title'>
							学生一键扫码带走
						</div>
					</div>
					<div class='col-gray adapt-des'>
						自动记录板书内容，学生可随堂扫码，多终端同步共享、批量下载，方便课后巩固复习。
					</div>
				</div>
			</div>
		</div>
	</section>
	<section class='w-100p m-b-20 stable border-box'>
		<div class='w-1200 block-center  '>
			<div class='col-white-opacity'>
				<div class='f-s-42 '>
					<div class='f-w-600 col-b-white'>
						高度稳定，运维0负担
					</div>
				</div>
				<div class='stable-one col-white-opacity'>
					<description-with-circle descriptionColor='rgba(255,255,255,0.7' description='专为高负荷、长时间应用而设计，支持7*24h不间断开机运行；'></description-with-circle>
				</div>
				<div class='stable-one col-white-opacity'>
					<description-with-circle descriptionColor='rgba(255,255,255,0.7' description='机体采用半封闭式设计，有效防尘、防潮，免调试、免维护。'></description-with-circle>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import interImageShow from '@/components/interImageShow';
import { defineComponent } from 'vue';
import DescriptionWithCircle from '@/views/production/components/DescriptionWithCircle';
export default defineComponent({
	name: 'AiCamera',
	components: {
		DescriptionWithCircle,
		interImageShow
	}
});
</script>
<style lang='scss' scoped>
.col-blue{
	color: $blue;
}
.col-title{
	color:$bTitleCol
}
.col-gray{
	color:$lightGray
}
.col-white-opacity{
	color:rgba(255,255,255,0.7);
}
.board{
	.board-img{
		background: no-repeat center center;
		background-size: cover;
	}

	.board-black{
		margin-top: 317px;
	}
	.board-des{
		width: 533px;
		color:$bContentCol;
	}
}
.occlusion{
	height: 480px;
	background-image: url('~@/assets/smartBoard/occlusion.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	.occlusion-des{
		width:336px;
	}
}
.write{
	height: 480px;
	background-image: url('~@/assets/smartBoard/board.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	.write-des{
		margin-left: 60%;
		width:336px;
	}
}
.reduction{
	padding: 90px 0;
}
.reduction-one{
	width:320px;
}
.reduction-two{
	width:335px;
}
.block{
	background-color: #E4E3E8;
	.adapt-one{
		margin-top: 32px;
		width: 341px;
		line-height: 28px;
	}
	.adapt-two{
		margin-top: 24px;
		width: 341px;
		line-height: 28px;
	}
	.adapt-des{
		width: 440px;
		margin-top: 32px;
		line-height: 28px;
	}
}
.adapt{
	padding: 110px 0;
	.adapt-one{
		margin-top: 32px;
		width: 341px;
		line-height: 28px;
	}
	.adapt-two{
		margin-top: 24px;
		width: 341px;
		line-height: 28px;
	}
	.adapt-des{
		width: 380px;
		margin-top: 32px;
		line-height: 32px;
	}
}
.product{
	height: 498px;
	background-image: url('~@/assets/smartBoard/product.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	.product-des{
		width: 346px;
	}
}
.stable{
	padding: 110px 0;
	height: 480px;
	background-image: url('~@/assets/smartBoard/stable.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	.stable-one{
		width: 376px;
		margin-top: 32px;
	}
}
</style>
