<template>
	<section class='w-100p train h-800 p-t-50 border-box flex align-center relative'>
		<div class='absolute w-100p h-100p train-img'
			 v-lazy-bg:img='[require("@/assets/blur/train_cart_bg.jpg"),true,require("@/assets/trainCart/trainBg.png")]'></div>
		<div class='w-1200 block-center relative'>
			<div class='f-s-42 f-w-600 '>可以<span class='col-b-blue f-w-600 '>时光倒流</span>的实训推车<br>让学生看得清、看得全、学得会</div>
			<div class='col-content col-title l-h-30 f-w-600 f-s-22 text-justify'>完美解决实训围观、视角单一、直播不能回退、过程无法记录、成果检验低效、实训场景受限、实训教研等问题，快速升级实训体验</div>
		</div>
	</section>
	<section class='w-100p train'>
		<div class='w-1200 block-center train-fun'>
			<div class='w-100p flex-space-b'>
				<div class='white-bg p-40 border-box brs30'>
					<div class='f-w-600 fun-one w-500 f-s-42'>能让时光倒流，重返任意时刻观看</div>
					<div class='f-s-42 f-w-600 fun-two'>学生都能学会</div>
					<div class='l-h-26 fun-three f-s-16'>示范教学时，可随时拖动进度条到任意时间点回放，重难点反复观看，确保学会。</div>
					<inter-image-show class='m-t-30' :img-width='499' :img-height='291' :img-url="require('@/assets/trainCart/time.png')"></inter-image-show>
				</div>
				<div class='white-bg p-40 border-box brs30'>
					<div class='f-w-600 fun-one w-500 f-s-42'>360°精准捕捉画面，比放大镜更清晰</div>
					<div class='f-s-42 f-w-600 fun-two'>学生都能看清</div>
					<div class='l-h-26 fun-three f-s-16'>4K自动对焦摄像机，教学演示放大8倍看；<br>360°转臂、阻尼关节，任意角度悬停，细节尽收眼底</div>
					<inter-image-show class='m-t-30' :img-width='499' :img-height='291' :img-url="require('@/assets/trainCart/clean.png')"></inter-image-show>
				</div>
			</div>
			<div class='w-100p flex-space-b m-t-40'>
				<div class='w-100p flex-space-b'>
					<div class='white-bg p-40 border-box brs30'>
						<div class='f-w-600 fun-fore  f-s-42'>不同工序示范操作画面自动切换</div>
						<div class='f-s-42 f-w-600 fun-two'>每道工序都能看全</div>
						<div class='l-h-26 fun-three f-s-16'>多个近景相机拍摄不同工序示范操作，无线投屏画面根据教师位置智能切换，不错过任何工序细节。</div>
						<inter-image-show class='m-t-30' :img-width='670' :img-height='387' :img-url="require('@/assets/trainCart/complete.png')"></inter-image-show>
					</div>
					<div class='white-bg p-40 border-box brs30'>
						<div class='f-w-600 fun-one w-260 f-s-42'>一键圈点批注</div>
						<div class='f-s-42 f-w-600 '>轻松标记重难点</div>
						<div class='l-h-26 fun-five f-s-16'>老师可随时通过全触控智慧实训终端圈点批注，被标记画面自动存储、U盘导出带走。</div>
						<inter-image-show class='m-t-30' :img-width='330' :img-height='379' :img-url="require('@/assets/trainCart/write.png')"></inter-image-show>
					</div>
				</div>
			</div>
		</div>
	</section>
	<section class='w-100p white-bg'>
		<div class='w-1200 block-center screen border-box'>
			<div class='flex-space-b'>
				<div>
					<div class='f-s-42 f-w-600 screen-des'>四分屏对比教学，</div>
					<div class='f-s-42 f-w-600 screen-des'>实训课堂更饱满</div>
					<div class='f-s-42 f-w-600'>实训成果检验更直观</div>
					<div class='screen-title l-h-26 f-s-16 text-justify'>可任选老师教学视频、学生实操视频等4个画面同步显示，便于学生实时比对，自我纠错。便于老师高效检验实训成果，及时点评反馈。</div>
				</div>
				<inter-image-show  :img-width='634' :img-height='407' :img-url="require('@/assets/trainCart/screen.png')"></inter-image-show>
			</div>
		</div>
	</section>
	<section class='w-100p spot'>
		<div class='w-1200 block-center spot-box border-box'>
			<div class='flex-space-b'>
				<inter-image-show  :img-width='634' :img-height='407' :img-url="require('@/assets/trainCart/spot.png')"></inter-image-show>
				<div>
					<div class='f-s-42 f-w-600 spot-gray'>课中、课后标签打点</div>
					<div class='f-s-42 f-w-600 '>快速定位重点片段</div>
					<div class='spot-des'>
						<description-with-circle  description='课中随时进行打点，形成标签，方便回看重要片段；'></description-with-circle>
					</div>
					<div class='spot-des'>
						<description-with-circle  description='对学生日常训练、实训考核视频打点评论，操作问题精准反馈。'></description-with-circle>
					</div>
				</div>
			</div>
		</div>
	</section>
	<section class='w-100p white-bg'>
		<div class='w-1200 block-center border-box car'>
			<div class='flex-space-b'>
				<div>
					<div class='f-s-42 f-w-600 col-gray'>自定义标签类型、</div>
					<div class='f-s-42 f-w-600 col-gray '> 快捷短语</div>
					<div class=' f-s-42 f-w-600 col-black'>打点便捷高效</div>
					<div class=' f-s-16 col-mar col-black-title l-h-26'>可事先设置标签类型，按照教学习惯添加常用快捷短语，便于打点标记。</div>
					<div class='col-gray col-five f-s-42 f-w-600'>学生端多屏同显</div>
					<div class='f-s-42 f-w-600 col-black'>教学内容近在眼前</div>
					<div class=' f-s-16  col-mar col-black-title l-h-26'>通过学生端多屏同步显示，学生在教室任意角落可实时共享教学内容。</div>
					<div class='col-gray col-five f-s-42 f-w-600'>学生实操过程自动录像</div>
					<div class='f-s-42 f-w-600 col-black'>课后随时点播回顾</div>
					<div class=' f-s-16  col-mar col-black-title l-h-26'>学生练习过程全程录像，老师可调取视频打点评论，方便学生课后回顾，查漏补缺。</div>
				</div>
				<inter-image-show  :img-width='600' :img-height='777' :img-url="require('@/assets/trainCart/car.png')"></inter-image-show>
			</div>
		</div>
	</section>
</template>
<script >
import { defineComponent } from 'vue';
import DescriptionWithCircle from '@/views/production/components/DescriptionWithCircle';
import interImageShow from '@/components/interImageShow';
export default defineComponent({
	components:{
		interImageShow,
		DescriptionWithCircle
	}
})
</script>
<style lang='scss' scoped>
$colorGray: rgba(36,37,51,0.5);
.train{
	background-color: #E6EBF0;
	.train-img{
		background: no-repeat center center;
		background-size: cover;
	}
	.col-title{
		width: 462px;
	}
	.col-blue{
		color: $blue;
	}
	.col-content{
		margin-top: 48px;
		color:$bContentCol;
	}
}
.train-fun{
	padding:70px 0 90px 0;
	.fun-one{
		color:$bContentCol;
	}
	.fun-three{
		color:$bContentCol;
		width: 375px;
		margin-top: 32px;
	}
	.fun-five{
		color:$bContentCol;
		margin-top: 32px;
		width: 330px;
	}
	.fun-fore{
		width: 588px;
		color:$bContentCol;
	}
}
.screen{
	padding: 60px 0;
	.screen-des{
		color:$colorGray;
	}
	.screen-title{
		color:$bContentCol;
		margin-top: 32px;
		width: 420px;
	}
}
.spot{
	background-color: $cyanGrey;
	.spot-box{
		padding: 60px 0;
		.spot-dot{
			margin-top: 32px;
		}
		.spot-gray{
			color: $colorGray;
		}
		.dot{
			background: $lightGray;
		}
		.spot-des{
			width: 384px;
			margin-top: 32px;
		}
	}

}
.car{
	padding: 90px 0;
	.col-gray{
		color:$colorGray;
	}
	.col-light{
		color:$lightGray;
	}
	.col-mar{
		width: 420px;
		margin-top: 32px;
	}
	.col-five{
		margin-top: 56px;
	}
	.col-black{
		color: $bTitleCol;
	}
	.col-black-title{
		color: $bContentCol;
	}
}
</style>
