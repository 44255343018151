import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36aa6d31"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "merge-platform-resource flex justify-center bg-light-grey",
  style: {"padding-bottom":"80px"}
}
const _hoisted_2 = { class: "w-1200 flex-shrink-0" }
const _hoisted_3 = { style: {"padding-top":"70px","padding-bottom":"80px","padding-left":"40px"} }
const _hoisted_4 = {
  class: "resource-card brs30 relative",
  style: {"height":"1100px"}
}
const _hoisted_5 = {
  class: "feature-img",
  style: {"width":"1128px","height":"398px","margin":"0 auto"}
}
const _hoisted_6 = { class: "feature-box p-t-40 p-l-40 flex m-b-80" }
const _hoisted_7 = { class: "f-s-20 f-w-500 col-b-blue l-h-32 m-b-8" }
const _hoisted_8 = {
  class: "f-s-14",
  style: {"color":"rgba(36, 37, 51, 0.70)"}
}
const _hoisted_9 = {
  class: "resource-cut feature-img relative",
  style: {"width":"1200px","height":"490px","margin":"0 auto"}
}
const _hoisted_10 = { class: "absolute t-40 l-40" }
const _hoisted_11 = { class: "f-s-20 f-w-500 col-b-blue l-h-32 m-b-8" }
const _hoisted_12 = {
  class: "f-s-14",
  style: {"color":"rgba(255, 255, 255, 0.70)"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MergePlatformTitle = _resolveComponent("MergePlatformTitle")!
  const _directive_lazy_bg = _resolveDirective("lazy-bg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_MergePlatformTitle, {
          imgUrl: _ctx.iconTitleOptions.imgUrl,
          title: _ctx.iconTitleOptions.title,
          description: _ctx.iconTitleOptions.description
        }, null, 8, ["imgUrl", "title", "description"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("div", _hoisted_5, null, 512), [
          [_directive_lazy_bg, require('@/assets/merge_platform_resource@2x.png'), "img"]
        ]),
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.features, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "w-300 m-r-110"
            }, [
              _createElementVNode("div", _hoisted_7, _toDisplayString(item.title), 1),
              _createElementVNode("div", _hoisted_8, _toDisplayString(item.description), 1)
            ]))
          }), 128))
        ]),
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cutDescList, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "resource-cut-left m-b-40",
                style: {"width":"309px"}
              }, [
                _createElementVNode("div", _hoisted_11, _toDisplayString(item.title), 1),
                _createElementVNode("div", _hoisted_12, _toDisplayString(item.description), 1)
              ]))
            }), 128))
          ])
        ])), [
          [_directive_lazy_bg, require('@/assets/merge_platform_resource_cut@2x.png'), "img"]
        ])
      ])
    ])
  ]))
}