import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "three-d-sensor bg-b-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ThreeDSensorBlackTech = _resolveComponent("ThreeDSensorBlackTech")!
  const _component_ThreeDSensorChip = _resolveComponent("ThreeDSensorChip")!
  const _component_ThreeDSensorAntiJam = _resolveComponent("ThreeDSensorAntiJam")!
  const _component_ThreeDSensorTrack = _resolveComponent("ThreeDSensorTrack")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ThreeDSensorBlackTech),
    _createVNode(_component_ThreeDSensorChip),
    _createVNode(_component_ThreeDSensorAntiJam),
    _createVNode(_component_ThreeDSensorTrack)
  ]))
}