
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import AIClassTerminal from '@/views/production/components/AIClassTerminal.vue';
import {
	PRODUCTION_CATEGORY_3D,
	PRODUCTION_CATEGORY_8500,
	PRODUCTION_CATEGORY_SMART_TEACHER,
	PRODUCTION_CATEGORY_GROUPS,
	PRODUCTION_CATEGORY_PLATFORM,
	PRODUCTION_CATEGORY_THREE_LESSON,
	PRODUCTION_CATEGORY_WIFI_DEV,
	PRODUCTION_CATEGORY_TRAIN_CART,
	PRODUCTION_CATEGORY_SMART_BLACKBOARD,
	PRODUCTION_CATEGORY_AI_CAMERA
} from '@/utils/production';
import GroupTerminal from '@/views/production/components/GroupTerminal.vue';
import MergePlatform from '@/views/production/components/MergePlatform.vue';
import ThreeClassTerminal from '@/views/production/components/ThreeClassTerminal.vue';
import ThreeDSensor from '@/views/production/components/ThreeDSensor.vue';
import WIFITerminal from '@/views/production/components/WIFITerminal.vue';
import HybridClassTerminal from '@/views/production/components/HybridClassTerminal.vue';
import TrainCart from '@/views/production/components/TrainCart.vue';
import SmartBlackboard from '@/views/production/components/SmartBlackboard.vue';
import AiCamera from '@/views/production/components/AiCamera.vue';

interface CategoryRoot {
	[key: string]: string;
}
const componentCategoryPair = {
	[PRODUCTION_CATEGORY_8500]: 'AIClassTerminal',
	[PRODUCTION_CATEGORY_SMART_TEACHER]: 'HybridClassTerminal',
	[PRODUCTION_CATEGORY_3D]: 'ThreeDSensor',
	[PRODUCTION_CATEGORY_TRAIN_CART]: 'TrainCart',
	[PRODUCTION_CATEGORY_SMART_BLACKBOARD]: 'SmartBlackboard',
	[PRODUCTION_CATEGORY_THREE_LESSON]: 'ThreeClassTerminal',
	[PRODUCTION_CATEGORY_WIFI_DEV]: 'WIFITerminal',
	[PRODUCTION_CATEGORY_GROUPS]: 'GroupTerminal',
	[PRODUCTION_CATEGORY_PLATFORM]: 'MergePlatform',
	[PRODUCTION_CATEGORY_AI_CAMERA]: 'AiCamera'
} as CategoryRoot;

export default defineComponent({
	name: 'Production',
	components: {
		WIFITerminal,
		ThreeDSensor,
		ThreeClassTerminal,
		MergePlatform,
		GroupTerminal,
		AIClassTerminal,
		TrainCart,
		SmartBlackboard,
		HybridClassTerminal,
		AiCamera
	},
	setup() {
		const route = useRoute();
		const currentComponent = computed(() => {
			// 默认显示“智慧教室终端”
			return componentCategoryPair[(route.params?.key as string) || PRODUCTION_CATEGORY_8500];
		});

		return {
			currentComponent
		};
	}
});
