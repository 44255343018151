import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d54ced3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "merge-platform-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "title-img",
      style: _normalizeStyle({ backgroundImage: `url(${_ctx.imgUrl})`, width: `${_ctx.imgWidth}px`, height: `${_ctx.imgHeight}px` })
    }, null, 4),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.isTextWhite ? 'col-b-white' : 'col-b-title', "title-text p-t-14 col-b-title f-w-600 f-s-42"]),
      style: {"line-height":"59px"}
    }, _toDisplayString(_ctx.title), 3),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.isTextWhite ? 'col-b-white' : 'col-b-title', "title-describe p-t-8 col-b-title f-w-400 f-s-20 opacity-7"]),
      style: {"line-height":"28px"}
    }, _toDisplayString(_ctx.description), 3)
  ]))
}