
import { defineComponent, reactive } from 'vue';
import MergePlatformTitle from '@/views/production/components/MergePlatformTitle.vue';

export default defineComponent({
	name: 'MergePlatformMedia',
	components: { MergePlatformTitle },
	setup() {
		const iconTitleOptions = reactive({
			imgUrl: require('@/assets/icon_platform_media@2x.png'),
			title: '媒体发布平台',
			description: '打造校园融媒体中心'
		});

		const features = reactive([
			{ title: '发布媒体任务', description: '统一管理发布任务，将文字、图片、音视频等全媒体资源推送到各教室播放' },
			{ title: '校园分区管理', description: '支持按时间、区域、等级等需求，定时、及时发布信息，加强信息覆盖率和有效性' },
			{ title: '在线直播发布', description: '批量推送直播视频到教室播放，适用于思政课集体学习、学术报告观摩等场景' },
			{ title: '建设校园文化', description: '创造浓厚的校园文化环境，拓宽学生精神世界、眼界，优化学校形象' }
		]);

		return {
			features,
			iconTitleOptions
		};
	}
});
