import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30e1065f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "merge-platform-media flex justify-center bg-b-white",
  style: {"padding-bottom":"80px"}
}
const _hoisted_2 = { class: "w-1200 flex-shrink-0" }
const _hoisted_3 = { style: {"padding-top":"70px","padding-bottom":"80px","padding-left":"40px"} }
const _hoisted_4 = {
  class: "media-card brs30 relative",
  style: {"height":"550px"}
}
const _hoisted_5 = {
  class: "feature-img",
  style: {"width":"1128px","height":"413px","margin":"0 auto"}
}
const _hoisted_6 = { class: "feature-box p-t-40 flex justify-center" }
const _hoisted_7 = { class: "f-s-20 f-w-500 col-b-title l-h-32 m-b-8" }
const _hoisted_8 = {
  class: "f-s-14",
  style: {"color":"rgba(36, 37, 51, 0.70)"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MergePlatformTitle = _resolveComponent("MergePlatformTitle")!
  const _directive_lazy_bg = _resolveDirective("lazy-bg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_MergePlatformTitle, {
          imgUrl: _ctx.iconTitleOptions.imgUrl,
          title: _ctx.iconTitleOptions.title,
          description: _ctx.iconTitleOptions.description
        }, null, 8, ["imgUrl", "title", "description"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("div", _hoisted_5, null, 512), [
          [_directive_lazy_bg, require('@/assets/merge_platform_media@2x.png'), "img"]
        ]),
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.features, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "w-240 m-r-50"
            }, [
              _createElementVNode("div", _hoisted_7, _toDisplayString(item.title), 1),
              _createElementVNode("div", _hoisted_8, _toDisplayString(item.description), 1)
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}