
import { defineComponent, reactive } from 'vue';

export default defineComponent({
	name: 'AIClassTerminalFeature',
	setup() {
		const features = reactive([
			{
				imgUrl: require('@/assets/feature_merge@2x.png'),
				title: '高度融合',
				description: '包含 50 多项教学应用，告别传统系统集成方式的纷繁复杂，堪称教室里的 “大手机”'
			},
			{
				imgUrl: require('@/assets/feature_easy@2x.png'),
				title: '高度易用',
				description: '遵循 Android 界面设计风格，将智能手机的使用习惯平移至智慧终端，操作简单免培训'
			},
			{
				imgUrl: require('@/assets/feature_stable@2x.png'),
				title: '高度稳定',
				description: '采用嵌入式硬件设计，运行更稳定，支持 7*24 小时不间断工作'
			}
		]);

		const cards = reactive([
			{
				imgUrl: require('@/assets/ai_teach@2x.png'),
				title: '智慧教学',
				description: '日常教学，操作简单',
				details: [
					{
						title: '全信号源自由圈点、批注',
						description:
							'支持直接圈点、批注本地电脑、外接笔记本、无线投屏、远端教室、小组屏等任意信号源内容，并可导出保存'
					},
					{
						title: '一键飞屏',
						description: '教学主屏板书、批注、资料等内容均可一键“飞”到任意小组屏'
					},
					{
						title: '一键广播',
						description: '任意信号源画面均可一键同步教室所有分组屏'
					}
				]
			},
			{
				imgUrl: require('@/assets/ai_center_control@2x.png'),
				title: '智能中控',
				description: '轻松管控教室',
				details: [
					{
						title: '设备控制',
						description: '支持集中控制触控一体机、投影幕布等设备'
					},
					{
						title: '信号源 “秒速” 切换 ',
						description: '本地电脑、远端教室、外接笔记本等信号源可秒速切换，无延迟'
					},
					{
						title: '权限管理',
						description: '对接学校教育资源平台，自动匹配个人数据'
					},
					{
						title: '物联管控',
						description: '智能控制灯光、空调、窗帘等设备（仅高级型教室支持）'
					}
				]
			},
			{
				imgUrl: require('@/assets/ai_record_video@2x.png'),
				title: '智能录播',
				description: '自动录播，媲美人工',
				details: [
					{
						title: '一键录制',
						description: '支持预约录制，实时预览，全自动或人工导播'
					},
					{
						title: '自由切换',
						description: '教师机讲授内容、教师画面和学生画面自由切换，支持教师机画面锁定'
					},
					{
						title: '自由导播',
						description: '全自动或人工导播，支持单画面、多画面、双分屏、画中画模式'
					}
				]
			},
			{
				imgUrl: require('@/assets/ai_group_teach@2x.png'),
				title: '分组教学',
				description: '无缝兼容雨课堂等各类学习平台互动软件，免装APP',
				details: [
					{
						title: '兼容性强',
						description: '不局限于任何特定教学互动软件，保护学校投资'
					},
					{
						title: '更适合TBL/PBL多学科协同教学',
						description: '支持IOS / Android / WIN10系统内置投屏，学生使用CAD/编程/艺术设计等软件进行讨论'
					},
					{
						title: '稳定可靠',
						description: '区别x86架构产品，ARM嵌入式设计更稳定'
					}
				]
			}
		]);

		return {
			features,
			cards
		};
	}
});
