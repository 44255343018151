
import { defineComponent } from 'vue';
import ThreeDSensorBlackTech from '@/views/production/components/ThreeDSensorBlackTech.vue';
import ThreeDSensorChip from '@/views/production/components/ThreeDSensorChip.vue';
import ThreeDSensorAntiJam from '@/views/production/components/ThreeDSensorAntiJam.vue';
import ThreeDSensorTrack from '@/views/production/components/ThreeDSensorTrack.vue';

export default defineComponent({
	name: 'ThreeDSensor',
	components: { ThreeDSensorTrack, ThreeDSensorAntiJam, ThreeDSensorChip, ThreeDSensorBlackTech }
});
