
import { defineComponent } from 'vue';
import MergePlatformDisplay from '@/views/production/components/MergePlatformDisplay.vue';
import MergePlatformSupervise from '@/views/production/components/MergePlatformSupervise.vue';
import MergePlatformDevice from '@/views/production/components/MergePlatformDevice.vue';
import MergePlatformResource from '@/views/production/components/MergePlatformResource.vue';
import MergePlatformMedia from '@/views/production/components/MergePlatformMedia.vue';
import MergePlatformAnalysis from '@/views/production/components/MergePlatformAnalysis.vue';

export default defineComponent({
	name: 'MergePlatform',
	components: {
		MergePlatformAnalysis,
		MergePlatformMedia,
		MergePlatformResource,
		MergePlatformDevice,
		MergePlatformSupervise,
		MergePlatformDisplay
	}
});
