<template>
	<div ref='target'>
		<img :src='imgUrl' alt='' :style='{width:`${imgWidth}px`,height:`${imgHeight}px`}'
			 :class='{["blur-img"]:!showCompleteStyle,["complete-img"]:showCompleteStyle}'>
	</div>
</template>
<script>
import { defineComponent, ref, computed } from 'vue';
import { useIntersectionObserver } from '@vueuse/core';
import { loadImg } from '@/utils/tools';

export default defineComponent({
	props: {
		//图片地址
		imgUrl: {
			type: String,
			default: ''
		},
		//宽
		imgWidth: {
			type: Number,
			default: 0
		},
		//高
		imgHeight: {
			type: Number,
			default: 0
		},
		//切换高倍图
		isSwitch: {
			type: Boolean,
			default: false
		},
		// 高倍图地址
		clearnessImg: {
			type: String,
			default: ''
		}
	},
	setup(props) {
		const target = ref(null);
		let imgUrl = ref(require('@/assets/static.png'));

		// 大图是否加载完成
		const isComplete = ref(false);

		// 是否展示完成的样式
		const showCompleteStyle = computed(() => {
			return (props.isSwitch && isComplete.value) || !props.isSwitch;
		});

		//到达滚动条可视区域图片展示
		const { stop } = useIntersectionObserver(
			target,
			([{ isIntersecting }], observerElement) => {
				if (isIntersecting) {
					imgUrl.value = props.imgUrl;


					if (props.isSwitch) {
						loadImg(props.clearnessImg).then(() => {
							imgUrl.value = props.clearnessImg;
							isComplete.value = true;
						});
					}
					//停止监听
					stop();
				}
			}
		);
		return {
			imgUrl,
			target,
			showCompleteStyle
		};
	}
});
</script>
<style lang='scss' scoped>
.blur-img {
	filter: blur(4px); // 模糊处理
}

.complete-img {
	filter: blur(0);
	//transition: all 0.7s;
}
</style>