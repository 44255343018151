import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ai-class-terminal bg-light-grey" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AIClassTerminalDescribe = _resolveComponent("AIClassTerminalDescribe")!
  const _component_AIClassTerminalFeature = _resolveComponent("AIClassTerminalFeature")!
  const _component_AIClassTerminalBlackPro = _resolveComponent("AIClassTerminalBlackPro")!
  const _component_AIClassTerminalWhite = _resolveComponent("AIClassTerminalWhite")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AIClassTerminalDescribe),
    _createVNode(_component_AIClassTerminalFeature),
    _createVNode(_component_AIClassTerminalBlackPro),
    _createVNode(_component_AIClassTerminalWhite)
  ]))
}