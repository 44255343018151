<template>
	<section class='w-100p hybrid-box p-t-50 border-box relative'>
		<div class='absolute w-100p h-100p hybrid-box-img'
			 v-lazy-bg:img='[require("@/assets/blur/hybrid_class_bg.jpg"),true,require("@/assets/hybridClass/teachBg.png")]'></div>
		<div class='w-1200 block-center relative'>
			<div class='f-s-42 col-blue f-w-600'>超级9合1</div>
			<div class='f-s-42 col-black f-w-600'>小身材，大用处</div>
			<div class='m-t-40 f-s-22 col-des l-h-30 f-w-600 text-justify'>快速将K12普通教室升级成支持混合式线上线下授课、常态化录播、听评课教研、远程巡课督导、网络教研、三个课堂、无线麦扩声等多种教学应用的智慧型教室</div>
		</div>
	</section>
	<section class='w-100p integrate-box' >
		<div class='w-1200 block-center'>
			<div class='w-100p text-center f-s-42 p-t-90 f-w-600'>
				高度集成，一机多能
			</div>
			<div class='f-s-20 flex-space-b  m-b-90 integrate'>
				<div class='w-300 l-h-28 f-w-400 text-justify'>4A音频处理器：AEC(回声消除)、AFC(反馈消除)、ANC(自动降噪)、AGC(自动增益)</div>
				<div>
					<div class='f-w-400'>常态化录播</div>
					<div class='m-t-20 f-w-400'>无线麦</div>
				</div>
				<div>
					<div class='f-w-400'>360°全向多媒体音箱</div>
					<div  class='m-t-20 f-w-400'>POE交换机</div>
				</div>
				<div>
					<div class='f-w-400'>8麦克风阵列</div>
					<div  class='m-t-20 f-w-400'>音视频采集卡</div>
				</div>
				<div>
					<div class='f-w-400'>音频功放</div>
					<div  class='m-t-20 f-w-400'>USB声卡</div>
				</div>
			</div>
		</div>
	</section>
	<section class='w-100p white-bg'>
		<div class='w-1200 block-center flex-space-b align-center video-meeting'>
			<div>
				<div class='f-s-42 f-w-600 connect-title'>广泛适用各种视频会议， 不改变用户习惯</div>
				<div class='connect-des'>兼容腾讯会议、钉钉、zoom、微软Teams等多种主流互动平台。</div>
				<div class='connect'>
					<inter-image-show :img-width='530' :img-height='121'
									  :img-url="require('@/assets/hybridClass/connect.png')"></inter-image-show>
				</div>
			</div>
			<inter-image-show :img-width='600' :img-height='338'
								  :img-url="require('@/assets/hybridClass/videoMeeting.png')"></inter-image-show>

		</div>
	</section>
	<section class='w-100p white-bg '>
		<div class='w-1200 block-center flex-space-b align-center h-500'>
			<inter-image-show :img-width='600' :img-height='338'
								  :img-url="require('@/assets/hybridClass/classTeach.png')"></inter-image-show>
			<div>
				<div class='teach-title f-s-42 '>
					<div class='f-w-600'>一套硬件，</div>
					<div class='f-w-600'>支持多种教育教学应用</div>
				</div>
				<div class='teach-des'>线上直播教学、OMO混合式教学、跨校区远程互动教学、国内国际学术交流、三个课堂、校本资源建设等。</div>
			</div>
		</div>
	</section>
	<section class='w-100p loud-box flex align-center'>
		<div class='loud-content w-1200 block-center flex-end'>
			<div class='loud-des'>
				<div class='f-s-40 f-w-600'>彻底解决回声问题</div>
				<div class='f-s-20 micro text-justify'>借鉴手机的麦克风、扬声器一体化设计，回声消除更彻底，适用于任何教室环境。</div>
				<div class='f-s-40 f-w-600 w-300 m-t-30'>8麦克风阵列，拾音范围＞16米</div>
				<div class='f-s-20 micro text-justify'>自动调节音量，老师在每个角落说话都能清晰采集。</div>
			</div>
		</div>
	</section>
	<section class='w-100p ai-class flex align-center'>
		<div class='flex-space-b w-1200 block-center  '>
			<div>
				<div class='ai-title'>
					<div class='f-s-42 f-w-600'>扩展性强，</div>
					<div class='f-s-42 f-w-600 '>可分步升级成AI智慧教室</div>
				</div>
				<div class='f-s-20 m-t-30 ai-net text-justify'>可选配物联网模块、AI课堂分析模块、IP广播模块等，让教室更智慧。</div>
				<div class='ai-des'>
					<div class='f-s-42 f-w-600'>轻松部署，</div>
					<div class='f-s-42 f-w-600 '>运维0负担</div>
					</div>
				<div class='f-s-20 ai-net m-t-30 text-justify'>产品结构简单，只需连接4根线缆，5分钟完成一间教室调试。</div>
			</div>
				<inter-image-show :img-width='549' :img-height='431'
								  :img-url="require('@/assets/hybridClass/aiClass.png')"></inter-image-show>
		</div>
	</section>
</template>
<script >
import { defineComponent } from 'vue';
import interImageShow from '@/components/interImageShow';
export default defineComponent({
	components:{
		interImageShow
	}
})
</script>
<style lang='scss' scoped>
.hybrid-box{
	height: 622px;
	.hybrid-box-img{
		background: no-repeat center center;
		background-size: cover;
	}
	.col-blue{
		color: $blue;
		margin-top: 142px;
	}
	.col-black{
		color: $bTitleCol;
	}
	.col-des{
		width: 570px;
		color: $bContentCol;
	}
}
.integrate-box{
	height: 379px;
	background-color: #F5F5F5;
	.integrate{
		color: $lightGray;
		margin-top: 56px;
	}
}
.video-meeting{
	height: 518px;
	.connect-title{
		width: 466px;
	}
	.connect{
		margin-top: 33px;
		margin-left: -31px;
	}
	.connect-des{
		color: $lightGray;
		margin-top: 32px;
		width: 466px;
		line-height: 32px;
	}

}
.teach-title{
	width: 471px;
}
.teach-des{
	width: 414px;
	margin-top: 32px;
	color: $lightGray;
	line-height: 32px;
}
.loud-box{
	height: 830px;
	background-color: #E6E6E6;
}
.loud-content{
	height: 690px;
	background-image: url('~@/assets/hybridClass/cloud.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}
.loud-des{
	margin-right: 106px;
}
.micro{
	width: 336px;
	line-height: 32px;
	margin-top: 32px;
	color: $lightGray;
}
.ai-class{
	height: 715px;
	background-color: #F5F5F5;
	.ai-title{
		width: 466px;

	}
	.ai-net{
		color: $lightGray;
		width: 374px;
		line-height: 32px;
	}
	.ai-des{
		margin-top: 100px;
	}
}
</style>
