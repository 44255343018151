import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-536e283d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "merge-platform-device flex justify-center bg-b-white",
  style: {"padding-bottom":"80px"}
}
const _hoisted_2 = { class: "w-1200 flex-shrink-0" }
const _hoisted_3 = { style: {"padding-top":"70px","padding-bottom":"80px","padding-left":"40px"} }
const _hoisted_4 = {
  class: "device-card bg-light-grey brs30 relative border-box",
  style: {"height":"788px"}
}
const _hoisted_5 = {
  class: "feature-box p-t-40 p-l-40",
  style: {"width":"380px"}
}
const _hoisted_6 = {
  class: "feature-img absolute",
  style: {"width":"908px","height":"539px","top":"119px","left":"420px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MergePlatformTitle = _resolveComponent("MergePlatformTitle")!
  const _component_description_with_circle = _resolveComponent("description-with-circle")!
  const _directive_lazy_bg = _resolveDirective("lazy-bg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_MergePlatformTitle, {
          imgUrl: _ctx.iconTitleOptions.imgUrl,
          title: _ctx.iconTitleOptions.title,
          description: _ctx.iconTitleOptions.description
        }, null, 8, ["imgUrl", "title", "description"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.features, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "device-text-item"
            }, [
              _createVNode(_component_description_with_circle, {
                title: item.title,
                description: item.description
              }, null, 8, ["title", "description"])
            ]))
          }), 128))
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_6, null, 512), [
          [_directive_lazy_bg, require('@/assets/merge_platform_device@2x.png'), "img"]
        ])
      ])
    ])
  ]))
}