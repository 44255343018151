
import { defineComponent, reactive } from 'vue';

export default defineComponent({
	name: 'GroupTerminalSuperiority',
	setup() {
		const supers = reactive([
			{
				imgUrl: require('@/assets/goup_terminal_protect@2x.png'),
				tile: '更适合TBL/PBL教学',
				description: '结合分组触控屏即可独立运行，支持本地信号源画面的切换、圈点、白板、内容导出等应用'
			},
			{
				imgUrl: require('@/assets/group_terminal_tbl@2x.png'),
				tile: '支持多路投屏、有声投屏',
				description: '免APP设计，支持IOS/Android/WIN10 系统内置投屏，支持6路手机、平板、电脑同时投屏，支持有声投屏'
			},
			{
				imgUrl: require('@/assets/group_terminal_arm@2x.png'),
				tile: '支持多成员图文、音频、视频互动',
				description: '可与其他分组终端互联，互相调取分组触控屏显示内容，对投屏内容进行协同圈点、批注，实现组内、组外高效互动'
			}
		]);

		return {
			supers
		};
	}
});
