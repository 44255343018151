import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "merge-platform o-x-h" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MergePlatformDisplay = _resolveComponent("MergePlatformDisplay")!
  const _component_MergePlatformSupervise = _resolveComponent("MergePlatformSupervise")!
  const _component_MergePlatformAnalysis = _resolveComponent("MergePlatformAnalysis")!
  const _component_MergePlatformDevice = _resolveComponent("MergePlatformDevice")!
  const _component_MergePlatformResource = _resolveComponent("MergePlatformResource")!
  const _component_MergePlatformMedia = _resolveComponent("MergePlatformMedia")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MergePlatformDisplay),
    _createVNode(_component_MergePlatformSupervise),
    _createVNode(_component_MergePlatformAnalysis),
    _createVNode(_component_MergePlatformDevice),
    _createVNode(_component_MergePlatformResource),
    _createVNode(_component_MergePlatformMedia)
  ]))
}