
import { defineComponent, reactive } from 'vue';

interface Feature {
	imgUrl: string;
	title: string;
	title2?: string;
	description: string;
}

export default defineComponent({
	name: 'ThreeClassTerminalFeature',
	setup() {
		const features: Feature[] = reactive([
			{
				imgUrl: require('@/assets/three_class_terminal_hang.png'),
				title: '身材小巧',
				title2: '壁挂式部署',
				description: '嵌入式架构一体化设计，稳定可靠'
			},
			{
				imgUrl: require('@/assets/three_class_terminal_meeting@2x.png'),
				title: '支持腾讯会议、钉钉会议',
				description: '可把导播画面和音频信号直接通过USB输出给教师电脑，无缝对接第三方PC视频会议系统'
			},
			{
				imgUrl: require('@/assets/three_class_terminal_easy.png'),
				title: '平移手机使用习惯，超级易用',
				description: '一块触控屏实现整个系统的可视化操作，一键录制，一键直播，拨号互动'
			},
			{
				imgUrl: require('@/assets/three_class_terminal_simple.png'),
				title: '高度集成，功能精简优化',
				description: '满足“三个课堂”应用，具有远程互动、直播、录播、远程巡课、听评课、协同教研等功能'
			}
		]);

		return {
			features
		};
	}
});
