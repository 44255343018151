
import { defineComponent, reactive } from 'vue';
import MergePlatformTitle from '@/views/production/components/MergePlatformTitle.vue';

export default defineComponent({
	name: 'MergePlatformResource',
	components: { MergePlatformTitle },
	setup() {
		const iconTitleOptions = reactive({
			imgUrl: require('@/assets/icon_platform_resource@2x.png'),
			title: '在线教学资源管理',
			description: '创建校本资源库，赋能在线教学应用'
		});

		const features = reactive([
			{ title: '大规模线上直播教学', description: '教师可快捷创建线上直播课堂，学生通过电脑、手机、pad等多终端扫码观看，实现优质教学资源快速传播。' },
			{ title: '教学视频资源点播', description: '学生可通过在线学习课程视频，支持教师画面、学生画面、课件画面等多画面资源模式点播。' },
			{ title: '建设优质校本资源库', description: '汇聚录播课、教学课件、教案等教学资源，创建校本资源库，实现优质教学资源持续沉淀、共享。' }
		]);

		const cutDescList = reactive([
			{ title: '助力学校打造“金课”', description: '通过建设精品课打造名校、名师课堂，推动课堂教学改革，提升教学质量。' },
			{ title: '在线剪辑微课', description: '支持对视频资源进行编辑和截取，秒速生成最终视频，无需下载即可分享。' }
		]);

		return {
			iconTitleOptions,
			cutDescList,
			features
		};
	}
});
