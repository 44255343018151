import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ce172bb"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "merge-platform-supervise flex justify-center bg-light-grey",
  style: {"padding-bottom":"80px"}
}
const _hoisted_2 = { class: "w-1200 flex-shrink-0" }
const _hoisted_3 = { style: {"padding-top":"70px","padding-bottom":"80px","padding-left":"40px"} }
const _hoisted_4 = { class: "feature-card-container flex justify-between" }
const _hoisted_5 = {
  class: "card-img absolute l-34 b-34",
  style: {"width":"512px","height":"348px"}
}
const _hoisted_6 = {
  class: "feature-bar-container m-t-40 bg-b-white brs30 relative border-box",
  style: {"height":"393px"}
}
const _hoisted_7 = {
  class: "absolute",
  style: {"width":"508px","left":"36px","top":"50%","transform":"translateY(-50%)"}
}
const _hoisted_8 = {
  class: "bar-img",
  style: {"width":"508px","height":"321px","right":"36px","top":"36px"}
}
const _hoisted_9 = { class: "feature-card-container m-t-40 flex justify-between" }
const _hoisted_10 = {
  class: "card-img absolute l-34 b-34",
  style: {"width":"512px","height":"348px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MergePlatformTitle = _resolveComponent("MergePlatformTitle")!
  const _component_description_with_circle = _resolveComponent("description-with-circle")!
  const _directive_lazy_bg = _resolveDirective("lazy-bg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_MergePlatformTitle, {
          imgUrl: _ctx.iconTitleOptions.imgUrl,
          title: _ctx.iconTitleOptions.title,
          description: _ctx.iconTitleOptions.description
        }, null, 8, ["imgUrl", "title", "description"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cards, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "feature-card-item flex-shrink-0 bg-b-white brs30 relative border-box",
            style: {"width":"580px","height":"597px","padding":"36px"}
          }, [
            _createVNode(_component_description_with_circle, {
              title: item.title,
              description: item.description
            }, null, 8, ["title", "description"]),
            _withDirectives(_createElementVNode("div", _hoisted_5, null, 512), [
              [_directive_lazy_bg, item.imgUrl, "img"]
            ])
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_description_with_circle, {
            title: "AI 辅助课堂管理",
            description: "采集教师行走热区、学生专注度、师生互动等数据，自动生成课堂分析报告，辅助教学评估。"
          })
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_8, null, 512), [
          [_directive_lazy_bg, require('@/assets/merge_platform_supervise_condition.png'), "img"]
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.autoSendReport, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "feature-card-item flex-shrink-0 bg-b-white brs30 relative border-box",
            style: {"width":"580px","height":"597px","padding":"36px"}
          }, [
            _createVNode(_component_description_with_circle, {
              title: item.title,
              description: item.description
            }, null, 8, ["title", "description"]),
            _withDirectives(_createElementVNode("div", _hoisted_10, null, 512), [
              [_directive_lazy_bg, item.imgUrl, "img"]
            ])
          ]))
        }), 128))
      ])
    ])
  ]))
}