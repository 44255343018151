
import { defineComponent, reactive } from 'vue';
import MergePlatformTitle from '@/views/production/components/MergePlatformTitle.vue';
import DescriptionWithCircle from '@/views/production/components/DescriptionWithCircle.vue';

export default defineComponent({
	name: 'MergePlatformSupervise',
	components: { DescriptionWithCircle, MergePlatformTitle },
	setup() {
		const iconTitleOptions = reactive({
			imgUrl: require('@/assets/icon_platform_supervise@2x.png'),
			title: 'AI辅助督导评课',
			description: '专注于教师发展和教师能力提升，全面提高教'
		});

		const cards = reactive([
			{
				imgUrl: require('@/assets/merge_platform_supervise_partrol.png'),
				title: 'AI辅助线上巡课',
				description: '在线实时巡课、实录巡课，支持1、4、9分屏选择，自动抓拍实证画面，全面把控课堂质量。'
			},
			{
				imgUrl: require('@/assets/merge_platform_supervise_measure.png'),
				title: 'AI辅助督导评课',
				description: ['支持自定义量规表、快捷短语、语音自动转文本、微格打点等多种便捷评课操作；', '课中、课后任意评课，快速生成课程听评报告，呈现优秀教师风采。']
			}
		]);

		const autoSendReport = reactive([
			{
				imgUrl: require('@/assets/merge_platform_supervise_auto_report.png'),
				title: '自动推送教学报告',
				description: ['为教师推送周、月、学期教学分析报告，促进教学能力成长；','定期为管理者推送全校师生分析数据，快速量化教学成果，总结教学得失。']
			},
			{
				imgUrl: require('@/assets/merge_platform_supervise_teach_count.png'),
				title: 'AI辅助协同教研',
				description: ['教研过程、教研成果、教研任务完成度可视化，统计教研活动频率、次数。']
			}
		]);

		return {
			iconTitleOptions,
			autoSendReport,
			cards
		};
	}
});
