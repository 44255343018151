
import { defineComponent } from 'vue';
import AIClassTerminalDescribe from '@/views/production/components/AIClassTerminalDescribe.vue';
import AIClassTerminalFeature from '@/views/production/components/AIClassTerminalFeature.vue';
import AIClassTerminalBlackPro from '@/views/production/components/AIClassTerminalBlackPro.vue';
import AIClassTerminalWhite from '@/views/production/components/AIClassTerminalWhite.vue';

export default defineComponent({
	name: 'AIClassTerminal',
	components: { AIClassTerminalWhite, AIClassTerminalBlackPro, AIClassTerminalFeature, AIClassTerminalDescribe }
});
