
import { defineComponent, reactive } from 'vue';
import MergePlatformTitle from '@/views/production/components/MergePlatformTitle.vue';

export default defineComponent({
	name: 'MergePlatformAnalysis',
	components: { MergePlatformTitle },
	setup() {
		const iconTitleOptions = reactive({
			imgUrl: require('@/assets/icon_platform_analaysis@2x.png'),
			title: '大数据分析',
			description: 'AI+ 大数据，助力学校教学质量评估和提升',
			isTextWhite: true
		});

		const features = reactive([
			{ title: 'AI课堂教学分析', description: 'AI分析教师讲台授课、走动授课、行走热区、板书、师生互动、课堂关注度、课堂参与度、到课率等数据，辅助教师进行教学研讨和反思。' },
			{ title: '学生行为、情绪分析', description: '自动检测学生读写、抬头、举手、趴桌子、玩手机等行为，自动识别学生愤怒、悲伤、恐惧、高兴、惊讶、平常等情绪，为课堂管理提供真实依据。' },
			{ title: '教学管理数据统计', description: 'AI实时生成考勤统计、直播统计、教研统计、听评课统计、巡课统计等报表，让教学管理者一目了然。' },
			{ title: '教学资源数据统计', description: 'AI实时更新区域内、校内精品课程、专递课堂、名校课堂、名师课堂等教学资源数量及点播量，生成动态数据看板。' },
			{ title: '教育信息化应用统计', description: '区域内智慧教室建设、教育信息化设备运行、教学功能使用率统计，助推教育信息化改革落地。' }
		]);

		return {
			iconTitleOptions,
			features
		};
	}
});
